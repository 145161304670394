import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';
// import { ColorTypes } from 'pdf-lib';
import { headers } from './index';

const getHeadersWithALBCookie = () => {
    const baseHeaders = headers();
    const albCookie = localStorage.getItem('albCookie');
    
    if (albCookie) {
        console.log("Found albCookie for /create call", albCookie)
        return {
            ...baseHeaders,
            headers: {
                ...baseHeaders.headers,
                "Set-Cookie": albCookie
            }
        };
    } else {
        console.log("Did not find albCookie in localStorage")
    }
    return baseHeaders;
 };

class CaseService {
    static generatePresignedUrls = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}/generate-presigned-urls`;
        return axios.post(url, payloadData, { ...headers() })
    }
    static AddCase = (payloadData) => {
        const url = `${AppConfig.PfqBaseUrl}${Constants.ApiUrl.create}`;
        return axios.post(url, payloadData, getHeadersWithALBCookie())
    }

    static GenerateCaseDocsAndGetDocsUrls = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.generate}`;
        return axios.post(url, payloadData, {
            'Content-Type': 'multipart/form-data',
            ...headers()
        })
    }

    static GetCases = (pageNumber, limit, searchText) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.case.getCases}?pageNumber=${pageNumber}&limit=${limit}&searchText=${searchText}`;
        return axios.get(url, { ...headers() }).then(resp => resp.data);
    }


    static GenerateUintarray = (payloadFiles) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.generateuintArray}`;
        return axios.post(url, payloadFiles, { ...headers() })
    }

    static GetErrorlog = (caseId, currentPage, showEntries) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.getErrorLog}`
        return axios.post(url, { caseId: caseId }, { ...headers() })
    }

    static deleteCase = (caseId) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.deleteCase}/${caseId}`
        return axios.delete(url, { ...headers() })
    }

    static preProcessMedicalRecord = (payloadData) => {
        const url = `${AppConfig.PfqBaseUrl}${Constants.ApiUrl.preProcessMedicalRecords}`;
        return axios.post(url, payloadData, getHeadersWithALBCookie())
    }
}

export default CaseService;
export { getHeadersWithALBCookie };
